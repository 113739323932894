@tailwind base;
@tailwind components;
@tailwind utilities;

/* main color = #ec5327
hover color = #BD4220 */

@layer components {
  .btn-primary {
    @apply items-baseline p-2 px-4 my-3 w-fit justify-center font-medium transition-all duration-300 ease-in-out disabled:opacity-50 bg-[#ec5327]  hover:bg-[#BD4220] border-none rounded-md text-white;
  }
  .pill {
    @apply border m-1 inline border-[#ec5327] rounded-md py-1 px-2 overflow-hidden;
  }
  .input-class {
    @apply pill bg-transparent focus:border-sky-300 focus:outline-none;
  }
  .navbar-li {
    @apply lg:mx-10 md:mx-6 hover:text-[#ec5327];
  }
  .secondary-color {
    @apply text-slate-500;
  }
  .icon {
    @apply inline mr-1;
  }
  .active {
    @apply text-[#ec5327];
  }

  .list-tools-card {
    @apply rounded shadow-md m-4 cursor-pointer hover:scale-105 ease-in duration-100 hover:shadow-[#ec5327] hover:shadow-md hover:text-[#ec5327];
  }
}

a {
  color: inherit !important;
  text-decoration: inherit !important;
}

body{
  background-color: rgb(249 250 251);
}